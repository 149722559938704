.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Media Queries */
@media screen and (max-width:600px) {
  .app-heading-tab{
    display:block !important;

  }
  #wrapper #content-wrapper{
    margin-left: 0px !important;
  }

    /* article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
      display: none;
    } */
    .card .card-header[data-bs-toggle="collapse"]::before {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 1.725rem;
      line-height: 35px;
   
    }
    .image-card div img {
      width: 120px;
      width: 120px;
    }
    a h6 {
      font-size: .8rem !important;
    }
    .filterOpened .sh_innerPortview{
      padding-left: 130px;
    }
    .sidebar_Filter.toggled {
   width: 10rem;
    }
}
@media screen and (max-width:768px) {
   Input[type="file"] {
    font-size: 10px !important;
    padding: 2px !important;
  }
}