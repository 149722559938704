.calendar-container {
    margin: 0 auto;
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px; /* Reduced margin */
    font-size: 0.9rem; /* Reduced font size */
    transition: all 0.3s ease; /* Transition for month change */
  }
  
  .calendar-title {
    margin: 0;
    color: #343a40;
  }
  
  .prev-btn,
  .next-btn {
    font-size: 1rem; /* Reduced font size */
    color: #6c757d;
    transition: color 0.3s ease; /* Transition for button color change */
  }
  
  .prev-btn:hover,
  .next-btn:hover {
    color: #007bff;
  }
  
  .calendar-grid {
    border-radius: 5px;
    /* overflow: hidden; */
  }
  
  .row {
    /* Removed border-bottom */
  }
  
  .calendar-col {
    /* padding: 6px; */
    /* transition: all 0.3s ease;  */
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin: auto;
    border-radius: 50%;
    position: relative;
    background-color: var(--snap-bg-white);
    -webkit-transition: background-color .1s linear;
    transition: background-color .1s linear;
  }
  
  .text-muted {
    color: #6c757d !important;
  }
  .calendar-col div:hover{
    background-color: #ddd;
    border-radius: 100%;
  }
  
  .current-date {
    background-color: var(--primary);
    border-radius: 100%;
    color: #ffffff;
    /* padding: 10px;  */

  }
  
  .selected-date {
    color: rgb(24, 90, 188);
    background-color: rgb(210, 227, 252);
    border-radius: 100%;
    /* padding: 10px; */

  }
  .sh_cardBox {
    /* Your existing styles */
    position: relative;
  }

  .calendar-container {
    position: relative;
/*    overflow: hidden;*/
  }
  .rotate-enter {
    transform: rotateY(-50deg);
    opacity: 0;
  }
  .rotate-enter-active {
    transform: rotateY(0deg);
    opacity: 1;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  }
  .rotate-exit {
    transform: rotateY(0deg);
    opacity: 1;
  }
  .rotate-exit-active {
    transform: rotateY(50deg);
    opacity: 0;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  }