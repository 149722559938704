/* CSS for slide-down */
.slide-enter {
    max-height: 0;
    opacity: 0;
    transform: translateY(-20px); /* Start from above */
  }
  
  .slide-enter-active {
    max-height: 300px; /* Adjust to desired max height */
    opacity: 1;
    transform: translateY(0); /* Slide to normal position */
    transition: opacity 300ms, max-height 300ms, transform 300ms;
  }
  
  .slide-exit {
    max-height: 300px;
    opacity: 1;
    transform: translateY(0);
  }
  
  .slide-exit-active {
    max-height: 0;
    opacity: 0;
    transform: translateY(-20px); /* Slide upwards */
    transition: opacity 300ms, max-height 300ms, transform 300ms;
  }
  


  css
Copy code
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms, transform 300ms;
}



