.rti--container * {
    box-sizing: border-box;
    transition: all 0.2s ease;
}

.rti--container {
    --rti-bg: var(--snap-formcontrol-bg);
    --rti-border:var(--snap-common-border);
    --rti-main: #3182ce;
    --rti-radius: 0.375rem;
    --rti-s: 0.5rem;
    --rti-tag: #dae7fb;
    --rti-tag-remove: #1d61f3;
    --rti-tag-padding: 0.15rem 0.25rem;

    /* Container Styles */
    align-items: center;
    background: var(--rti-bg);
    border: 1px solid var(--rti-border);
    border-radius: var(--rti-radius);
    display: flex;
    flex-wrap: wrap;
    gap: var(--rti-s);
    line-height: 1.4;
    padding: var(--rti-s);
}

.rti--container:focus-within {
    /* border-color: var(--rti-main); */
    /* box-shadow: var(--rti-main) 0px 0px 0px 1px; */
}

.rti--input {
    border: 0;
    outline: 0;
    background-color: var(--snap-formcontrol-bg);
    color:#0c0459;
    font-size: inherit;
    line-height: inherit;
    width: 50%;
}

.rti--tag {
    align-items: center;
    background: var(--rti-tag);
    border-radius: var(--rti-radius);
    color: #1d61f3;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1;
    display: inline-flex;
    justify-content: center;
    padding: 0.25em 0.4em;
}

.rti--tag button {
    background: none;
    border: 0;
    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: 600;
    cursor: pointer;
    line-height: inherit;
    color: var(--rti-tag-remove);
    padding: 0 var(--rti-s);
}

.rti--tag button:hover {
    color: var(--rti-tag-remove);
}